/**
 * Replace the custom CSS in the Hub2 stylesheet with the provided styles.
 *
 * Looks for the following comment blocks in the "rm-custom-css" stylesheet:
 * /*! BEGIN STYLESHEET_HUB2 *\/
 * ...
 * /*! END STYLESHEET_HUB2 *\/
 *
 * and replaces the contents with the provided styles.
 *
 * @example
 * // Before
 * <style title="rm-custom-css">
 *   h1 { color: red; }
 *   /*! BEGIN STYLESHEET_HUB2 *\/
 *   .something-old { color: blue; }
 *   /*! END STYLESHEET_HUB2 *\/
 *   button { color: green; }
 * </style>
 *
 * // After liveUpdateHub2CSS('.something-new { color: purple; }');
 * <style title="rm-custom-css">
 *   h1 { color: red; }
 *   /*! BEGIN STYLESHEET_HUB2 *\/
 *   .something-new { color: purple; }
 *   /*! END STYLESHEET_HUB2 *\/
 *   button { color: green; }
 * </style>
 */
function liveUpdateHub2CSS(styles: string) {
  const styleElement = document.querySelector('style[title="rm-custom-css"]');
  const disableCustomCss = new URLSearchParams(window.location.search).get('disableCustomCss') === 'true';
  if (!styleElement || disableCustomCss) return;

  const overrideStyleElement = document.createElement('style');
  overrideStyleElement.title = 'rm-custom-css';

  const text = `${styleElement?.textContent}`;

  const beginMarker = '/*! BEGIN STYLESHEET_HUB2 */';
  const endMarker = '/*! END STYLESHEET_HUB2 */';
  const beforeBeginMarker = text.split(beginMarker)[0];
  const afterEndMarker = text.split(endMarker)[1];

  const update = beforeBeginMarker + beginMarker + styles + endMarker + afterEndMarker;

  overrideStyleElement.innerText = update;
  styleElement?.replaceWith(overrideStyleElement);
}

export default liveUpdateHub2CSS;
